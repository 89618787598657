console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');

import './skip-link-focus-fix';
import './smoothscroll';
import './navigation';
import './scrollyclasses';
import './viewport-height';
import './fw-video';
import './sliders';
import './accordions';
import './fancybox';

jQuery(function ($) {

	//page
	var $hamburger = $(".hamburger"),
		$site = $("body"),
		$menu = $(".main-navigation"),
		$menuitems = $(".menu-item"),
		$screenOverlay = $(".screen-overlay");

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on("click", function () {
		$hamburger.toggleClass("is-active");
		$site.toggleClass("menu-open");
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});
	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass("menu-open filters-open");
		$menu.removeClass("toggled");
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass("is-active");
	}
	$screenOverlay.on('click', closeMenu);
	$('#close-filters').on('click', closeMenu);

	$(document).bind('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});


	var openTabs = [];

	function filterAccordions() {
		//need to save the active items to the dom
		$('.filter-wrapper h4').each(function () {
			$(this).on('click', function () {
				let headingClass = $(this).parent()[0].className;
				if (!$(this).hasClass('active')) {
					openTabs.push(headingClass);

					$(this).addClass('active')
					$(this).next().slideDown();
				} else {
					console.log(headingClass)
					$(this).removeClass('active')
					$(this).next().slideUp();
					openTabs = openTabs.filter(function (e) {
						;
						return e !== headingClass
					})
				}
			})
		})

		// $('.sf-option-active').each(function() {
		// 	$(this).parent().siblings('h4').addClass('active')
		// })
	}
	filterAccordions()

	$(document).on("sf:ajaxfinish", ".searchandfilter", function () {
		filterAccordions($('.' + openTabs[i]));
		for (var i = 0; i < openTabs.length; i++) {
			// console.log($('.'+openTabs[i]))
			$('.' + openTabs[i]).find('ul, [class*="sf-"]').show()
			$('.' + openTabs[i] + ' h4').addClass('active')
		}
	});


	$(".copy").each(function () {
		$(this).on('click', function (e) {
			e.preventDefault();
			var url = $(this).attr('data-permalink');
			var $temp = $("<input>");
			var toast = "<div class='confirmation'>URL copied to clipboard.</div>";

			$("body").append($temp, toast);
			$temp.val(url).select();
			document.execCommand("copy");
			$temp.remove();
			setTimeout(() => {
				$('.confirmation').remove();
			}, 1500);
		});
	});

	$('.slider-toggle').each(function () {
		$(this).on('click', function () {
			$('.slider-toggle').removeClass('active');
			$('.model-gallery').removeClass('active')

			let target = $(this).attr('data-target');
			$(this).addClass('active');
			$('[data-title="' + target + '"]').addClass('active');
			// $('html, body').animate({
			// 	scrollTop: $('[data-title="'+target+'"]').offset().top
			// }, 1000 );

		})
	});

	

	$(document).on('favorites-cleared', function() {

		window.location.reload();
		
	});

	

	$('#refine').on('click', function () {
		$site.toggleClass("filters-open");
	})
	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
	AOS.init();

	var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
	// if (viewportWidth < 1024 || $('body').hasClass('is-touch')) {
		$('#menu-item-550 > div > a').each(function () {
			$(this).on('click', function (e) {
				e.preventDefault()
				$(this).parent().parent().toggleClass('active')
			})
		})
		$('#menu-item-633 > .sub-menu a').each(function () {
			$(this).on('click', function () {
				closeMenu();
			})
		})
	// }



});